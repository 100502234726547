import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ContextProvider, ThemeContext } from '../context/themeContext';
import themes from '../theme';
import Footer from '../components/Footer/Footer';
import GlobalStyle from '../components/Global.style.js';
import LayoutWrapper from './layout.style.js';

class PostLayout extends Component {
  // TODO This should be a hook eventually, once it's supported: https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;

    return (
      <ContextProvider>
        <InnerLayout children={children} />
      </ContextProvider>
    );
  }
}

const InnerLayout = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <ThemeProvider
      theme={themeContext.contextState === 'dark' ? themes.light : themes.dark}
    >
      <ParallaxProvider>
        <GlobalStyle />
        <LayoutWrapper>
          <main className="post-layout">{children}</main>
          <Footer />
        </LayoutWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PostLayout;
