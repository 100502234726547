import styled from 'styled-components';
import { themeGet } from 'styled-system';

const NavbarWrapper = styled.nav`
  width: 100%;
  display: flex;
  padding: 25px;
  align-items: center;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;

  a {
    color: ${themeGet('color.3')};
    font-style: italic;
    font-size: 1.2em;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    i {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
  }
`;

export default NavbarWrapper;
