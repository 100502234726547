import React from 'react';
import { Link } from 'gatsby';
import NavbarWrapper from './PostNavbar.style';

const PostNavbar = () => (
  <NavbarWrapper>
    <Link to="/">
      <i
        style={{
          background: `url("https://s3.eu-central-1.amazonaws.com/nov-assets/fe-assets/arrow-back.svg") center center / contain`,
        }}
      />
      <span>Terug naar home</span>
    </Link>
  </NavbarWrapper>
);

export default PostNavbar;
